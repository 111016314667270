import {isMobile} from 'react-device-detect';

const Staff = ({rule,name,description,img}) => {

    return ( 
        <div style={{
            display: "grid",
            gridTemplateColumns: (isMobile)?"14em auto":"29em auto",
            marginBottom: "3em",
            marginLeft: "7%",
            marginRight: "7%"
        }}>

            <div style={{
                backgroundImage: img,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundColor: "#ffffff",
                height: (isMobile)?"10em":"20em",
                width: (isMobile)?"10em":"20em",
                margin: "2em 1.5em 2em 0",
                boxShadow: "-15px 19px 30px #000"
            }}>

            </div>
            <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "80%",
                marginRight: "auto"
            }}>
                <h1 style={{
                    marginBottom: "0.2em",
                    textTransform: "none",
                    fontSize: (isMobile)?"5vw":"4vw",
                }}>{rule}</h1>
                {/*<h2 style={{
                    marginTop: "0",
                    marginBottom: "1.5em"
                }}>{name}</h2>*/}
                <p>{description}</p>
            </div>
        </div>
     );
}
 
export default Staff;