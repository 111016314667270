import { Link } from 'react-router-dom'

const NavBar = () => {

    var link = ""
    if(window.location.href.slice(-1) == "/"){
        link = window.location.href.slice(0,-1).split("/").reverse()[0];
    }
    else{
        link = window.location.href.split("/").reverse()[0];
    }

    link = link.toLowerCase();

    console.log(link);
    
    let navbarStyle = {
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: "5em",
        paddingLeft: "2em",
        backgroundColor: "#00000000",
        backgroundImage: "linear-gradient(#00000066 0%, #00000066 65%, #00000000 100%)"
    }

    return (
        <div style={navbarStyle}>
            <img src="img/Logo.png" alt="logo" style={{
                height: "10em",
                width: "9em",
                objectFit: "contain",
            }}/>
            <div className="links">
                <Link to="/" style={(link == "metroflex.it" || link == "www.metroflex.it")?{color: "#ccff00"}:{}}>Home</Link>
                <Link to="/ChiSiamo"  style={(link == "chisiamo")?{color: "#ccff00"}:{}}>Chi siamo</Link>
                <Link to="/Servizi"  style={(link == "servizi")?{color: "#ccff00"}:{}}>Servizi</Link>
            </div>
        </div>
    );
}
 
export default NavBar;