import {isMobile} from 'react-device-detect';
import Hero from "../components/Hero";
import Servizio from '../components/Servizio';
import { BrowserView, MobileView} from 'react-device-detect';

import NavBar from '../components/NavBar.js';
import NavBarMobile from '../components/NavBarMobile.js';

const Servizi = () => {
    return (
        <div className="home">
            <Hero title={["LA ","FO","RZA DELLA ","PAL","ESTR","A"]} text="La cura del dettaglio e una minuziosa attenzione al cliente rendono la Metroflex gym un centro polifunzionale adatto a chiunque." background="url(./img/home/carousel/forza_della_palestra.jpg)" ></Hero>
            
            <h1 style={{
                marginLeft: "7%",
                marginTop: "1.5em",
                marginBottom: "1.5em",
            }}>Servizi</h1>

            <Servizio name="SALA PESI" img="url(./img/servizi/1.jpg)" description={"Una sala che vanta circa 600 mq con le migliori attrezzature sul mercato per un allenamento senza eguali."}></Servizio>
            <Servizio name="COACHING PERSONALIZZATO" img="url(./img/servizi/2.jpg)" description="Il nostro team di professionisti vi seguirà in un percorso esclusivo per il raggiungimento dei vostri obiettivi tramite programmi personalizzati di allenamento e consigli alimentari con un costante monitoraggio scandito da live check periodici in studio."></Servizio>
            <Servizio name="SCHEDE DI ALLENAMENTO SU MISURA" img="url(./img/servizi/3.jpg)" description="Scheda di allenamento personalizzata per il miglioramento psicofisico, della performance e dell'esperienza in sala pesi."></Servizio>
            <Servizio name="PERSONAL ONE TO ONE" img="url(./img/servizi/4.jpg)" description="Le nostre ore di personal one to one consistono in un allenamento dedicato durante il quale sarete a stretto contatto con i nostri trainer per migliorare l'esperienza in sala e imparare il corretto utilizzo dei macchinari in funzione dei personali obiettivi."></Servizio>




            <BrowserView>
                <NavBar></NavBar>
            </BrowserView>
            <MobileView>
                <NavBarMobile></NavBarMobile>
            </MobileView>


        </div>
    );
}
 
export default Servizi;