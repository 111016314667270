import { Link } from 'react-router-dom'

const NavBarMobile = () => {

    var link = ""
    if(window.location.href.slice(-1) == "/"){
        link = window.location.href.slice(0,-1).split("/").reverse()[0];
    }
    else{
        link = window.location.href.split("/").reverse()[0];
    }

    link = link.toLowerCase();

    console.log(link);
    
    let navbarStyle = {
        position: "absolute",
        top: "0",
        left: "0",
        right: "0",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingRight: "1.5em",
        paddingLeft: "1.2em",
        backgroundColor: "#16161600",
        backgroundImage: "linear-gradient(#00000066 0%, #00000066 65%, #00000000 100%)"
    }

    let btnStyle = {
        display: "flex",
        flexDirection: "column",
        fontSize: "min(4vw , 0.4cm)"
    }

    let iconStyle = {
        height: "2em",
        width: "100%",
        marginTop: "0.5em",
        objectFit: "contain"
    }

    return (
        <div style={navbarStyle}>
            <img src="img/Logo.png" alt="logo" style={{
                height: "20vw",
                width: "25vw",
                objectFit: "contain"
            }}/>
            <div style={{
                display: "flex",
                flexDirection: "row"
            }}>
                <div style={btnStyle}>
                    {/*<img src="img/ico1.png" alt="home" style={iconStyle}/>*/}
                    <Link to="/" style={(link == "metroflex.it" || link == "www.metroflex.it")?{color: "#ccff00"}:{}}>Home</Link>
                </div>
                <div style={btnStyle}>
                    {/*<img src="img/ico2.png" alt="chi siamo" style={iconStyle}/>*/}
                    <Link to="/ChiSiamo" style={(link == "chisiamo")?{color: "#ccff00"}:{}}>Chi siamo</Link>
                </div>
                <div style={btnStyle}>
                    {/*<img src="img/ico3.png" alt="servizi" style={iconStyle}/>*/}
                    <Link to="/Servizi" style={(link == "servizi")?{color: "#ccff00"}:{}}>Servizi</Link>
                </div>
            </div>
        </div>
    );
}
 
export default NavBarMobile;