import { BrowserView, MobileView, isMobile} from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import Carousel from "../components/Carousel";
import ImageList from "../components/ImageList";

import NavBar from '../components/NavBar.js';
import NavBarMobile from '../components/NavBarMobile.js';

const Home = () => {

    const history = useHistory();

    var storia = `I risultati di bullismo, errori e fallimenti subiti nella nostra vita hanno scatenato dentro di noi la determinazione e la forza di volontà di riscattarci dalle nostre “ferite”. Cosi' nasce nel 2019 il nostro primo progetto di palestra, risultato di sudore, dedizione e sacrifici sia personali sia familiari i quali ci hanno donato un primo periodo di “rinascita” purtoppo interrotto dall’arrivo della pandemia mondiale. Dopo un lungo periodo di sconforto e depressione, siamo tornati ad oggi, nel 2022, più forti e determinati di prima, dando vita all’attuale Metroflex gym. Un centro d’inclusione per qualsiasi tipologia di fisico, dal dilettante al culturista, uno spazio dedicato a macchinari di ultima generazione che offrono un’esperienza efficace e incisiva per i loro utilizzatori. Un mondo dove tutto è possibile.`;
    var equipment = `All'interno della palestra potete trovare le migliori attrezzature marchiate Panatta. Un'azienda orientata al miglior risultato fondata su principi di eccellenza qualitativa, innovazione tecnica, stile ed emozioni realizzando attrezzatura da palestra altamente professionale destinato per il vero allenamento fisico.`;
    var servizi = `Una fusione di programmi d’allenamento personalizzati, analisi corporee, coaching su misura per te, ti faranno godere dei risultati targati Metroflex.`;

    return (
        <div className="home">

            <Carousel items={[
                {title: ["LA ","PAL","ESTR","A","",""],
                text:"Una perfetta fusione tra eleganza e praticità",
                background:"url(./img/home/carousel/la_palestra.jpg)"},
                {title: ["LA ","FO","RZA DELLA ","PAL","ESTR","A"],
                text:"La cura del dettaglio e una minuziosa attenzione al cliente rendono la Metroflex gym un centro polifunzionale adatto a chiunque",
                background:"url(./img/home/carousel/forza_della_palestra.jpg)"},
                {title:["LO ","ST","AFF","","",""],
                text:"Personale altamente qualificato. Professionisti specializzati e in costante aggiornamento per garantire i più alti livelli di competenza e professionalità",
                background:"url(./img/home/carousel/staff.jpg)"},
                {title:["","FEDE","RICA E ","ALE","SSI","O"],
                text:"Due fratelli in “affari” che hanno fatto dello sport uno stile di vita",
                background:"url(./img/home/carousel/federica_e_alessio.jpg)"}]} ></Carousel>
            
            {/* LA NOSTRA STORIA */}
            <BrowserView>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    backgroundImage: "url(./img/home/storia.jpg)",
                    backgroundSize: "contain",
                    backgroundPosition: "right",
                    backgroundRepeat: "no-repeat",
                    height: "33vw",
                    margin: "8vw 0"
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        width: "40%",
                        height: "100%",
                        marginLeft: "7%"
                    }}>
                        <h1 style={{ margin: "0" }}>LA NOSTRA STORIA</h1>
                        <p style={{ lineHeight: "1.5em", fontSize: "1.1vw"}}>{storia}</p>
                        <button onClick={() => history.push('/chisiamo')}><div>Scopri di più</div></button>
                    </div>
                </div>
            </BrowserView>
            <MobileView>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "80vw",
                    width: "100%",
                    backgroundImage: "url(./img/home/storia.jpg)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundColor: "#161616cc",
                    backgroundBlendMode: "hue",
                    backgroundPosition: "bottom"
                }}>
                    <h1 style={{margin: "0 0 0 7%", fontSize: "8vw"}}>la nostra storia</h1>
                    <p style={{margin: "0 7% 0 7%", fontSize: "2.7vw"}}>{storia}</p>
                    <button style={{margin: "1em 0 0 7%"}} onClick={() => history.push('/chisiamo')}><div>Scopri di più</div></button>
                </div>
            </MobileView>

            {/* EQUIPMENT */}
            <h1 style={{
                marginLeft: "7%",
                fontSize: (isMobile)?"8vw":"4.5vw"
            }}>EQUIPMENT</h1>
            <p style={{ lineHeight: "1.5em", fontSize: (isMobile)?"2.7vw":"1.1vw", marginLeft: "7%", marginRight: (isMobile)?"7%":"30%"}}>{equipment}</p>
            <button style={{
                marginLeft: "7%",
                marginBottom: "2em"
            }} onClick={() => history.push('/servizi')}><div>Scopri di più</div></button>

            <ImageList speed="0.175" h="22vw" w="35vw" images={["url(./img/home/slider1/1.jpg)","url(./img/home/slider1/2.jpg)","url(./img/home/slider1/3.jpg)",]}></ImageList>
            <ImageList speed="0.175" h="22vw" w="35vw" images={["url(./img/home/slider2/1.jpg)","url(./img/home/slider2/2.jpg)","url(./img/home/slider2/3.jpg)",]} reverse={true}></ImageList>

            {/* SERVIZI */}
            <BrowserView>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "start",
                    backgroundImage: "url(./img/home/servizi.jpg)",
                    backgroundSize: "contain",
                    backgroundPosition: "right",
                    backgroundRepeat: "no-repeat",
                    height: "33vw",
                    margin: "8vw 0"
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        width: "40%",
                        height: "100%",
                        marginLeft: "7%"
                    }}>
                        <h1 style={{ margin: "0" }}>servizi</h1>
                        <p style={{ lineHeight: "1.5em", fontSize: "1.1vw"}}>{servizi}</p>
                        <button onClick={() => history.push('/servizi')}><div>Scopri di più</div></button>
                    </div>
                </div>
            </BrowserView>
            <MobileView>
                <div style={{
                    marginTop: "1em",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "75vw",
                    width: "100%",
                    backgroundImage: "url(./img/home/servizi.jpg)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundColor: "#161616cc",
                    backgroundBlendMode: "hue",
                    backgroundPosition: "left"
                }}>
                    <h1 style={{margin: "0 0 0 7%", fontSize: "8vw"}}>servizi</h1>
                    <p style={{margin: "0 7% 0 7%", fontSize: "2.7vw"}}>All'interno della palestra potete trovare una sala pesi, il servizio di coaching personalizzato, la creazione di schede di allenamento e il personal one to one.</p>
                    <button style={{margin: "1em 0 0 7%"}} onClick={() => history.push('/servizi')}><div>Scopri di più</div></button>
                </div>
            </MobileView>



            <BrowserView>
                <NavBar></NavBar>
            </BrowserView>
            <MobileView>
                <NavBarMobile></NavBarMobile>
            </MobileView>


        </div>
    );
}
 
export default Home;