import { useRef, useEffect } from "react";

const Carousel = ({items}) => {
    const ref = useRef(null);
    const caro = useRef(null);

    useEffect(() => {
        var c = new Ccc(caro.current,1);
        c.autoScroll(8);
    }, []);


    return (
        <section ref={caro} className="carousel_canvas">
            <section className="carousel_photos">
                {items.map((item) => {
                    return <div className='hero' ref={ref}
                    style={{ backgroundImage: item.background}}> 
                                <div>
                                    <h1>{item.title[0]}<b>{item.title[1]}</b>{item.title[2]}<b>{item.title[3]}</b>{item.title[4]}<b>{item.title[5]}</b></h1>
                                    <p>{item.text}</p>
                                </div>
                            </div>;
                })}
            </section>
        </section>
    )

    /*const ref = useRef(null);

    return (
        <div className='hero-container'>
            <Rerousel itemRef={ref} interval={5000} stop={false}>
                {items.map((item) => {
                    return <div className='hero' ref={ref}
                    style={{ backgroundImage: item.background}}> 
                                <div>
                                    <h1>{item.title[0]}<b>{item.title[1]}</b>{item.title[2]}<b>{item.title[3]}</b>{item.title[4]}<b>{item.title[5]}</b></h1>
                                    <p>{item.text}</p>
                                </div>
                            </div>;
                })}
            </Rerousel>
        </div>
    );*/
}

export default Carousel;



class Ccc {

    wait;
    abort;

    objContainer;
    time;

    constructor(canvas, time = 0.3) {
        this.objContainer = canvas.children[0];
        this.objContainer.style.transitionDuration = this.time + "s";
        var max = this.objContainer.childElementCount - 1;
        var imgs = this.objContainer.children;
        var a = imgs[0];
        for (let i = 0; i < (max+1); i++) {
            this.objContainer.insertBefore(imgs[i*2].cloneNode(true),a);
        }
        this.objContainer.style.transform = "translate(" + (-((max+1)*100)+0.02) + "vw)";
        this.time = time;
        this.wait = false;
        this.abort = false;
    }

    autoScroll(interval){
        window.setTimeout(()=>{
            if(!this.abort){
                this.right();
            }
            this.abort = false;
            this.autoScroll(interval);
        },interval*1000);
    }

    right() {
        if(!this.wait){
            this.wait = true;
            this.abort = true;
            this.objContainer.firstChild.style.width = "0";
            this.objContainer.firstChild.style.minWidth = "0";
            window.setTimeout(()=>{
                var first = this.objContainer.firstChild;
                first.style.width = "100vw";
                first.style.minWidth = "100vw";
                this.objContainer.appendChild(first);
                this.wait = false;
            },this.time*1000)
        }
    }

    left() {
        if(!this.wait){
            this.wait = true;
            this.abort = true;
            var last = this.objContainer.lastChild;
            last.style.width = "0";
            last.style.minWidth = "0";
            this.objContainer.insertBefore(last,this.objContainer.firstChild);
            window.requestAnimationFrame(()=>{
                window.requestAnimationFrame(()=>{
                    last.style.width = "100vw";
                    last.style.minWidth = "100vw";
                })
            })
            window.setTimeout(()=>{
                this.wait = false;
            },this.time*1000)
        }
    }
}