import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { BrowserView, MobileView} from 'react-device-detect';

import NavBar from './components/NavBar.js';
import NavBarMobile from './components/NavBarMobile.js';
import Home from './pages/Home.js';
import ChiSiamo from './pages/ChiSiamo.js';
import Servizi from './pages/Servizi.js';
import Footer from './components/Footer.js';

function App() {
    return (
    <Router>
        <div className="App">
            <div className="content" style={{minHeight: "60vh"}}>
                <Switch>
                    <Route exact path="/">
                        <Home></Home>
                    </Route>
                    <Route exact path="/chisiamo">
                        <ChiSiamo></ChiSiamo>
                    </Route>
                    <Route exact path="/servizi">
                        <Servizi></Servizi>
                    </Route>
                </Switch>
            </div>
            <Footer></Footer>
        </div>
    </Router>
    );
}

export default App;
