import { isMobile} from 'react-device-detect';

const ImageList = ({images, h, w, mh, mw, reverse, speed}) => {

    h = (h === undefined)? "22vw" : h; 
    w = (w === undefined)? "35vw" : w;
    mh = (mh === undefined)? "55vw" : mh; 
    mw = (mw === undefined)? "87.5vw" : mw;
    speed = (speed === undefined)? 1 : speed; 
    reverse = (reverse === undefined)? false: reverse;

    let s = (images.length/speed)*10;

    return (
        <div style={{
            overflowX: "hidden",
            width: "86%",
            marginLeft: "auto",
            marginRight: "auto",
            height: "min-content"
        }}>
            <div className="image-list" style={{
                animationDuration: s+"s",
                animationDirection: (reverse)? "reverse" : "normal",
                display: "flex",
                flexDirection: "row",
                width: "min-content",
                animationName: "imageListScroll",
                animationTimingFunction: "linear",
                animationIterationCount: "infinite"
            }}>
                {images.map((image) => { return (
                    <div style={{
                        backgroundImage: image,
                        height: (isMobile)?mh:h,
                        width: (isMobile)?mw:w,
                        margin: (isMobile)?"0.7em 0.7em":"1em 1em",
                        backgroundSize: "cover"
                        }}>
                    </div>
                )})}
                {images.map((image) => { return (
                    <div style={{
                        backgroundImage: image,
                        height: (isMobile)?mh:h,
                        width: (isMobile)?mw:w,
                        margin: (isMobile)?"0.7em 0.7em":"1em 1em",
                        backgroundSize: "cover"
                        }}>
                    </div>
                )})}
                {images.map((image) => { return (
                    <div style={{
                        backgroundImage: image,
                        height: (isMobile)?mh:h,
                        width: (isMobile)?mw:w,
                        margin: (isMobile)?"0.7em 0.7em":"1em 1em",
                        backgroundSize: "cover"
                        }}>
                    </div>
                )})}
                {images.map((image) => { return (
                    <div style={{
                        backgroundImage: image,
                        height: (isMobile)?mh:h,
                        width: (isMobile)?mw:w,
                        margin: (isMobile)?"0.7em 0.7em":"1em 1em",
                        backgroundSize: "cover"
                        }}>
                    </div>
                )})}
            </div>
        </div>
     );
}
 
export default ImageList;