import { isMobile} from 'react-device-detect';
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div style={{
            backgroundColor: "#000000",
            width: "100%",
            marginTop: (isMobile)?"1em":"2em",
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
        }}>
            <div className="footer-nav" style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                minWidth: (isMobile)?"23em":"32em",
                maxWidth: "90%",
                paddingTop: "4em"
            }}>
                <div
                    className="footer-nav" style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    textAlign: "right",
                    marginRight: "2em"
                }}>
                    <div>
                        <Link to="/" style={{fontSize: "1.5em", paddingLeft: "0", paddingRight: "0"}}>HOME</Link>
                    </div>
                    <div>
                        <Link to="/ChiSiamo" style={{fontSize: "1.5em", paddingLeft: "0", paddingRight: "0"}}>CHI SIAMO</Link>
                    </div>
                    <div>
                        <Link to="/Servizi" style={{fontSize: "1.5em", paddingLeft: "0", paddingRight: "0"}}>SERVIZI</Link>
                    </div>
                </div>
                <div style={{
                    overflowX: "visible",
                    width: "8em",
                    whiteSpace: "nowrap"
                }}>
                    <p className="link" style={{fontSize: "1.5em", color: "#ffffff", paddingLeft: "0", paddingRight: "0"}}>Contatti</p>
                    <p style={{color: "#999999", fontSize: "100%"}}>info@metroflex.it </p>
                    <p style={{color: "#999999", fontSize: "100%"}}>+39 3408819388</p>
                    <p style={{color: "#999999", fontSize: "100%"}}>Via Giovanni Gronchi 14 (Nucleo ind. di Pile), 67100 L'Aquila</p>
                </div>
            </div>
            <div className="copiright" style={{marginTop: "3em", marginBottom: "1em", fontSize: "1.5em"}}>
                <p style={{color: "#999999", }}>Copyright by @<b style={{color: "#ccff00"}}>metroflex</b> - 2021</p>
            </div>
        </div>
    );
}
 
export default Footer;