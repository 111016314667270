import { isMobile} from 'react-device-detect';
import Hero from "../components/Hero";
import Staff from "../components/Staff";
import { BrowserView, MobileView} from 'react-device-detect';

import NavBar from '../components/NavBar.js';
import NavBarMobile from '../components/NavBarMobile.js';

const ChiSiamo = () => {

    var storia = `I risultati di bullismo, errori e fallimenti subiti nella nostra vita hanno scatenato dentro di noi la determinazione e la forza di volontà di riscattarci dalle nostre “ferite”. Così nasce nel 2019 il nostro primo progetto di palestra, risultato di sudore, dedizione e sacrifici sia personali sia familiari i quali ci hanno donato un primo periodo di “rinascita” purtoppo interrotto dall’arrivo della pandemia mondiale. Dopo un lungo periodo di sconforto e depressione, siamo tornati ad oggi, nel 2022, più forti e determinati di prima, dando vita all’attuale Metroflex gym. Un centro d’inclusione per qualsiasi tipologia di fisico, dal dilettante al culturista, uno spazio dedicato a macchinari di ultima generazione che offrono un’esperienza efficace e incisiva per i loro utilizzatori. Un mondo dove tutto è possibile.`;

    return (
        <div className="home">
            <Hero title={["","FEDE","RICA E ","ALE","SSI","O"]} text="Due fratelli in “affari” che hanno fatto dello sport uno stile di vita." background="url(./img/home/carousel/federica_e_alessio.jpg)" ></Hero>
            
            {/* LA NOSTRA STORIA */}
            <h1 style={{
                marginLeft: "7%",
                marginRight: "7%",
                fontSize: (isMobile)?"8vw":"4.5vw",
            }}>LA NOSTRA STORIA</h1>
            <p style={{
                fontSize: (isMobile)?"2.7vw":"1,1vw",
                margin: "0 7% 4em 7%"
            }}>{storia}</p>

            <h1 style={{
                marginLeft: "7%",
                fontSize: (isMobile)?"8vw":"4.5vw"
            }}>chi siamo</h1>

            <Staff img="url(./img/chi_siamo/1.jpg)" rule="CEO e FONDATRICE" name="Federica Tacconi" description="Titolo di Personal Trainer conseguito alla FIF, iscritta al registro CONI degli istruttori."></Staff>
            <Staff img="url(./img/chi_siamo/2.jpg)" rule="CEO e FONDATORE" name="Alessio Tacconi" description="Istruttore specializzato nella cultura fisica, attività con sovraccarichi finalizzate al fitness e al benessere psicofisico tramite la scuola di formazione dell'associazione italiana cultura sport (AICS), iscritto al registro CONI degli istruttori."></Staff>
            <Staff img="url(./img/chi_siamo/4.jpg)" rule="PERSONAL TRAINER, PREPARATORE ATLETICO e EDUCATORE ALIMENTARE" name="Nicola Maccarini" description="Atleta agonista laureato in scienze motorie con specialistica in fisioterapia"></Staff>
            <Staff img="url(./img/chi_siamo/7.jpg)" rule="PERSONAL TRAINER e PREPARATRICE ATLETICA" name="Loredana massimiani" description="Laureata in Biologia della Salute e della Nutrizione e Personal Trainer per l'estetica femminile di II livello (CSEN), iscritta al registro CONI degli istruttori."></Staff>
            {/*<Staff img="url(./img/chi_siamo/8.jpg)" rule="PERSONAL TRAINER e PREPARATORE ALTLETICO" name="Lorenzo scolletta" description="Istruttore specializzato in Bodybuilding e fitness formato tramite l'AICS, iscritto al registro CONI degli istruttori."></Staff>*/}
            <Staff img="url(./img/chi_siamo/5.jpg)" rule="RECEPTIONIST" name="Giulia Brusco" description=""></Staff>
            <Staff img="url(./img/chi_siamo/6.jpg)" rule="RECEPTIONIST" name="Brigida Golia" description=""></Staff>





            <BrowserView>
                <NavBar></NavBar>
            </BrowserView>
            <MobileView>
                <NavBarMobile></NavBarMobile>
            </MobileView>
            



        </div>
    );
}
 
export default ChiSiamo;