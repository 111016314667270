const Hero = ({title,background,text}) => {

    return (
        <div className='hero-container'>
            <div className='hero'
            style={{ backgroundImage: background}}> 
                <div>
                    <h1>{title[0]}<b>{title[1]}</b>{title[2]}<b>{title[3]}</b>{title[4]}<b>{title[5]}</b></h1>
                    <p>{text}</p>
                </div>
            </div>
        </div>
    );
}


export default Hero;