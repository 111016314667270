const Servizio = ({name, description, img}) => {
    return ( 
        <div className="servizio" style={{
            backgroundImage: img
        }}>

            <h1>{name}</h1>

            <p>{description}</p>

        </div>
     );
}
 
export default Servizio;